@font-face {
  font-family: "Arpona";
  font-weight: 600;
  src: url(./fonts/ArponaBold.otf) format("opentype");
}

@font-face {
  font-family: "Arpona";
  font-weight: 400;
  src: url(./fonts/ArponaMedium.otf) format("opentype");
}

@font-face {
  font-family: "Arpona";
  font-weight: 700;
  src: url(./fonts/ArponaBlack.otf) format("opentype");
}
