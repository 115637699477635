@import "~react-perfect-scrollbar/dist/css/styles.css";
// @import './prismjs';

html,
#root,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

// :not(pre) > code {
//   font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
//   background-color: #eeeeee;
//   padding: 2px 4px;
//   direction: ltr;
//   white-space: pre;
//   word-spacing: normal;
//   word-break: normal;
//   line-height: 1.5;
//   font-size: 14px;
// }
